<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Salary</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Branch Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Salary Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <!-- <div class="report-tool-icon mr-1">
            <i class="fas fa-download"></i>
          </div> -->
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-10 d-flex mr-0 pr-0">
          <select v-model="filter.size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select class="ml-2" v-model="filter.session_year">
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}/{{ parseInt(year.substring(year.length - 2)) + 1 }}
            </option>
          </select>
          <ul class="ml-2">
            <li>
              <a href="javascript:;" @click="prevMonth">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li><a href="javascript:;" @click="thisMonth"> Month </a></li>
            <li>
              <a href="javascript:;" @click="nextMonth">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <div class="ml-2">
            <input
              type="text"
              v-model="filter.key"
              class="filter-input"
              placeholder="Search..."
            />
          </div>
        </div>
        <div class="col-md-2 ml-0 pl-0">
          <select
            class="filter-input ml-2 float-right"
            v-model="route"
            @change="filterReportType"
          >
            <option value="/general-report/salary-report">Employee Wise</option>
            <option value="/general-report/salary-report/branch-wise">
              Branch Wise
            </option>
            <option value="/general-report/salary-report/month-wise">
              Month Wise
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-25p">Branch</th>
              <th class="wd-10p">Gross Salary</th>
              <th class="wd-10p">Tds</th>
              <th class="wd-10p">Net Salary</th>
              <th class="wd-10p">Total Advance</th>
              <th class="wd-10p">Advance Due</th>
              <th class="wd-10p text-right table-end-item">Salary Due</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="table-start-item">1</th>
              <td>Kathmandu</td>
              <td>500000</td>
              <td>5000</td>
              <td>495000</td>
              <td>200000</td>
              <td>100000</td>
              <td class="text-right table-end-item">40000</td>
            </tr>
          </tbody>
        </table>
        <Pagination />
      </div>
    </div>
  </div>
</template>
<script>
import { Search } from "../../../../../mixins/search";
import Pagination from "../../../components/pagination/pagination";
import { mapGetters } from "vuex";

export default {
  mixins: [Search],
  components: {
    Pagination,
  },
  data() {
    return {
      sessionYears: ["2021"],
      route: this.$route.path,
      account: "",
      branch: "",
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists4",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
  },
  methods: {
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    print() {
      window.print();
    },
    filterReportType() {
      this.$router.push(this.route);
    },
    filterByBranch() {
      this.$store.commit(
        "setApiUrl",
        `api/salary/summary/filter/branch/month-wise/year/${this.filter.session_year}`
      );
      this.$store.commit("sendData", {
        branch: this.branch,
      });
    },
    filterByAccount() {
      this.$store.commit(
        "setApiUrl",
        `api/salary/summary/filter/account/month-wise/year/${this.filter.session_year}`
      );
      this.$store.commit("sendData", {
        account: this.account,
      });
    },

    filterByMonth() {
      this.$store.commit(
        "setApiUrl",
        "api/salary/summary/filter/month/day-wise"
      );
      this.$store.commit("sendData", {
        size: this.filter.size,
        month: this.filter.full_month,
      });
    },
    filterByTwoDates() {
      this.$store.commit(
        "setApiUrl",
        "api/salary/summary/filter/date/day-wise"
      );
      this.$store.commit("sendData", this.filter);
    },
    getData() {
      this.$store.commit(
        "getData9",
        `api/salary/summary/year/${this.filter.session_year}`
      );
      this.$store.commit(
        "getData",
        `api/salary/summary/month-wise/year/${this.filter.session_year}`
      );
    },
  },
  beforeMount() {
    this.$store.commit("getData2", `api/salary/account`);
    this.$store.commit("getData4", `api/branch`);

    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>